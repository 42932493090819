<template>
  <q-card style="min-width: 600px">
    <q-toolbar class="bg-primary text-white">
      <q-toolbar-title> Excluir Conta</q-toolbar-title>
      <q-btn flat round dense icon="mdi-close" @click="$emit('close-popup')" />
    </q-toolbar>
    <q-stepper
      v-model="step"
      ref="formAdvertiser"
      color="primary"
      animated
      contracted
      flat
      class="full-width justify-center"
    >
      <q-step class="row q-col-gutter-md full-width justify-center" title="" :name="1">
        <div class="col-12 text-center q-mt-md q-mb-xl text-h5">Tem certeza que deseja excluir sua conta?</div>

        <div class="col-12 row justify-center">
          <q-btn
            color="primary"
            style="width: 120px"
            class="q-mr-sm q-px-md"
            no-caps
            outline
            unelevated
            label="Sim"
            @click="retrieveAffiliateDataForDeletion"
          />

          <q-btn
            color="negative"
            style="width: 120px"
            no-caps
            label="Cancelar"
            class="q-px-md q-ml-sm"
            unelevated
            @click="$emit('close-popup')"
          />
        </div>
      </q-step>

      <q-step class="row q-col-gutter-md full-width" title="" :name="2">
        <div class="col-12 q-mt-md q-mb-xl text-center text-h6">
          {{
            affiliatePaymentData.paymentInformation
              ? `Você possui R$ ${affiliatePaymentData.totalComissions} aprovados para pagamento.`
              : `Você possui R$ ${affiliatePaymentData.totalComissions} aprovados para pagamento. Para receber esses valores, seus dados de pagamento precisam estar devidamente preenchidos na plataforma.`
          }}
        </div>

        <div class="col-12 row justify-center">
          <q-btn
            color="primary"
            style="min-width: 120px"
            class="q-mr-sm q-px-md"
            no-caps
            outline
            unelevated
            label="Prosseguir"
            @click="() => step++"
          >
            <!-- <q-tooltip content-class="bg-accent" -->
            <!--   >Prosseguir com a exclusão e desistir do recebimento do valor.</q-tooltip -->
            <!-- > -->
          </q-btn>

          <q-btn
            color="positive"
            style="min-width: 120px"
            no-caps
            :label="
              affiliatePaymentData.paymentInformation
                ? 'Solicitar pagamento do saldo disponível'
                : 'Cadastrar Dados de Pagamento'
            "
            class="q-px-md q-ml-sm"
            unelevated
            @click="affiliatePaymentData.paymentInformation ? askForPayment() : $emit('goToPayment')"
          />
        </div>
      </q-step>

      <q-step class="row q-col-gutter-md full-width" title="" :name="3">
        <div class="col-12 q-my-md text-center pq-mt-md q-mb-xl text-h6">
          Aceite os termos abaixo para prosseguir com a exclusão:
        </div>

        <div class="col-12 q-my-md text-center pq-mt-md q-mb-xl text-subtitle1">
          <q-list bordered separator class="text-justify">
            <q-item>
              1. Comissões Pendentes: Se houver saldo pendente em conversões, as comissões que ainda não foram validadas
              serão perdidas ao concordar com os termos.
            </q-item>
            <q-item>
              2. Pausa dos Canais: Ao concordar com os termos, todos os seus canais serão pausados imediatamente,
              impossibilitando a geração de novas conversões ou solicitações de aprovação em campanhas.
            </q-item>
            <q-item>
              3. Pagamentos Pendentes: Se houver saldo aguardando pagamento, o setor financeiro da plataforma realizará
              a quitação conforme os procedimentos normais de pagamento.
            </q-item>
            <q-item>
              4. Bloqueio Temporário de Cadastro: Após a exclusão da conta, o CPF ou CNPJ do será automaticamente
              bloqueado por um período de 6 meses, impedindo a criação de um novo cadastro na plataforma durante esse
              tempo.
            </q-item>
            <q-item>
              5. Tempo de Processamento: O processo de exclusão de conta pode levar até 30 dias úteis para ser
              concluído, desde que não haja pagamentos solicitados a serem quitados.Se houver, a exclusão será
              finalizada após a quitação dos valores.
            </q-item>
            <q-item>
              6. Irreversibilidade do Processo: O processo de exclusão de conta não poderá ser revertido após a
              solicitação.
            </q-item>
          </q-list>
        </div>

        <div class="col-12 row justify-center">
          <q-btn
            color="primary"
            style="min-width: 120px"
            class="q-mr-sm q-px-md"
            no-caps
            outline
            unelevated
            label="Aceitar"
            @click="() => step++"
          />

          <q-btn
            color="negative"
            style="min-width: 120px"
            no-caps
            label="Recusar"
            class="q-px-md q-ml-sm"
            unelevated
            @click="$emit('close-popup')"
          />
        </div>
      </q-step>

      <q-step class="row q-col-gutter-md full-width justify-center" title="" :name="4">
        <div class="col-12 text-center q-mt-md q-mb-xl text-h5">Confirme suas credenciais para exclusão:</div>

        <q-form @submit="validate" class="col-12 q-pa-md">
          <q-input
            outlined
            bg-color="white"
            type="email"
            v-model="form.username"
            :placeholder="$t('enter_the_email')"
            clearable
            class="rounded"
            clear-icon="close"
            label="Email"
            @keyup.enter="submit"
            lazy-rules
            :rules="[isRequired, isEmail]"
          >
            <template v-slot:prepend>
              <q-icon name="mdi-email-outline" color="primary" />
            </template>
          </q-input>
          <q-input
            clearable
            bg-color="white"
            clear-icon="close"
            outlined
            class="rounded"
            :placeholder="$t('enter_the_pass')"
            v-model="form.password"
            ref="password"
            :label="$t('password')"
            :type="showPassword ? 'text' : 'password'"
            @keyup.enter="submit"
            lazy-rules
            :rules="[isRequired]"
          >
            <template v-slot:prepend>
              <q-icon name="mdi-lock-open-outline" color="primary" />
            </template>
            <template v-slot:append>
              <q-btn
                flat
                round
                color="primary"
                no-caps
                :icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click="showPassword = !showPassword"
              />
            </template>
          </q-input>

          <q-btn color="positive" type="submit" unelevated>Confirmar</q-btn>
        </q-form>
      </q-step>
    </q-stepper>
  </q-card>
</template>

<script>
import AffiliateService from '@/services/AffiliateService';
import AuthService from '@/services/AuthService';

export default {
  name: 'AffiliateAccountExclusionModal',
  mixins: [AffiliateService, AuthService],
  data() {
    return {
      step: 1,
      affiliatePaymentData: {
        totalComissions: 0,
        paymentInformation: false
      },
      form: {
        username: '',
        password: ''
      },
      showPassword: false,
      hasPendingComissions: false
    };
  },
  methods: {
    async retrieveAffiliateDataForDeletion() {
      try {
        this.onLoading(true);
        const { data } = await this.getAffiliateDataForDeletion();
        this.affiliatePaymentData = data;

        if (this.affiliatePaymentData.totalComissions == 0) {
          this.step = 3;
          return;
        }

        this.step = 2;
        this.hasPendingComissions = true;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async askForPayment() {
      try {
        this.onLoading(true);
        const { status } = await this.requestPayment();
        if (status == 200) {
          this.successNotify(
            'Pedido de pagamento realizado! Aguarde a liberação para solicitar novamente a exclusão de conta'
          );
          this.$emit('close-popup');
        }
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async validate() {
      var wasValidated = await this.login(this.form);

      try {
        this.onLoading(true);
        if (wasValidated) {
          const { status } = await (this.hasPendingComissions
            ? this.affiliateRequestExclusionNoComission()
            : this.affiliateRequestExclusion());
          if (status == 200) {
            this.successNotify('Solicitação de exclusão enviada.');
            this.$emit('close-popup');
          }
        }
      } catch (error) {
        this.errorNotify('Não foi possível realizar a solicitação de exclusão de conta. Tente novamente mais tarde.');
      } finally {
        this.onLoading(false);
      }
    },

    async login(form) {
      this.onLoading(true);
      var wasValidated = false;
      try {
        if (!form.username) throw this.$t('validator.required.email');
        if (!form.password) throw this.$t('validator.required.password');
        const { data, status } = await this.authLogin(this.form);
        if (status === 200 && data.login.user.user_id == this.user.user_id) {
          wasValidated = true;
        } else {
          this.errorNotify('Não foi possível confirmar suas credenciais. Tente novamente.');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify('Não foi possível confirmar suas credenciais. Tente novamente.');
      } finally {
        this.onLoading(false);
      }

      return wasValidated;
    }
  }
};
</script>

<style></style>
