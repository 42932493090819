<template>
  <q-card>
    <q-form @submit="onChangePassword">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Alterar senha</q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="$emit('close-popup')" />
      </q-toolbar>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-12" v-if="user.profile == 'afiliado'">
          <q-input
            clearable
            clear-icon="close"
            outlined
            label="Senha Atual"
            v-model="currentPassword"
            :rules="[isRequired]"
            :type="showCurrentPassword ? 'text' : 'password'"
            :disable="!canUserReset"
          >
            <template v-slot:append>
              <q-btn
                flat
                no-caps
                :label="showCurrentPassword ? $t('hide') : $t('show')"
                @click="showCurrentPassword = !showCurrentPassword"
              />
            </template>
          </q-input>
        </div>
        <div class="col-12">
          <q-input
            clearable
            clear-icon="close"
            ref="password"
            outlined
            :label="'Nova Senha'"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[isRequired, isPassword]"
            lazy-rules
            maxlength="16"
            :disable="!canUserReset"
          >
            <template v-slot:append>
              <q-btn
                flat
                no-caps
                :label="showPassword ? $t('hide') : $t('show')"
                @click="showPassword = !showPassword"
              />
            </template>
          </q-input>
          <q-linear-progress
            class="abslute q-mt-sm"
            style="top: 2px"
            size="25px"
            :color="verifyPasswordStrength.color"
            :value="verifyPasswordStrength.value"
          >
            <div class="absolute-full flex flex-center" v-if="verifyPasswordStrength.label">
              <q-badge color="white" :text-color="verifyPasswordStrength.color" :label="verifyPasswordStrength.label" />
            </div>
          </q-linear-progress>
        </div>
        <div class="col-12">
          <q-input
            clearable
            clear-icon="close"
            ref="checkPassword"
            outlined
            :label="$t('confirm_pass')"
            v-model="checkPassword"
            :type="showCheckPassword ? 'text' : 'password'"
            maxlength="16"
            lazy-rules
            :rules="[isRequired, isPassword]"
            :disable="!canUserReset"
          >
            <template v-slot:append>
              <q-btn
                flat
                no-caps
                :label="showCheckPassword ? $t('hide') : $t('show')"
                @click="showCheckPassword = !showCheckPassword"
              />
            </template>
          </q-input>
        </div>
      </q-card-section>
      <q-card-actions v-if="canUserReset" align="right">
        <q-btn no-caps class="q-px-md" unelevated label="Cancelar" outline color="negative" v-close-popup />
        <q-btn no-caps class="q-px-md" unelevated label="Salvar" color="primary" type="submit" />
      </q-card-actions>
      <q-card-actions v-else align="center">
        <p class="text-center text-bold">
          Redefinição de senha bloqueada por excesso de tentativas. Clique no botão "Esqueceu sua senha" para se
          deslogar e enviar um email de redefinição para seu endereço de acesso.
        </p>
        <q-btn
          no-caps
          class="q-px-md q-mb-md"
          unelevated
          label="Esqueceu sua senha? Clique aqui!"
          color="negative"
          @click="onForgotPassword"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import AffiliateService from '@/services/AffiliateService.js';
import AffiliateMixin from '@m/AffiliateMixin.js';
import AccountMixin from '@m/AccountMixin.js';
import UserService from '@/services/UserService.js';
export default {
  name: 'AffiliateFormChangePassword',
  props: {
    id: {
      type: [String, Number],
      require: true
    },
    email: {
      type: [String],
      require: true
    }
  },
  mixins: [AffiliateService, AffiliateMixin, AccountMixin, UserService],
  data() {
    return {
      password: null,
      checkPassword: null,
      currentPassword: null,
      showPassword: false,
      showCheckPassword: false,
      showCurrentPassword: false,
      canUserReset: false
    };
  },
  computed: {
    verifyPasswordStrength() {
      const score = this.scorePassword(this.password);
      if (score > 80) return { label: 'Forte', value: 1, color: 'positive' };
      if (score > 60) return { label: 'Boa', value: 0.7, color: 'info' };
      if (score >= 30) return { label: 'Fraca', value: 0.5, color: 'warning' };
      if (score >= 20) return { label: 'Muito fraca', value: 0.2, color: 'negative' };
      return { label: '', value: 0, color: '' };
    }
  },
  methods: {
    async onChangePassword() {
      try {
        this.onLoading(true);
        if (!this.password) throw 'Senha não informada';
        if (this.password !== this.checkPassword) throw 'Senhas não conferem';
        const { status } = await this.updateAffiliatePassword(this.id, this.password, this.currentPassword);

        if (status == 200) {
          this.passwordDialog = false;
          this.successNotify('Senha Atualizada com sucesso');
        }
      } catch (error) {
        if (error.response.status == 401 && error.response.data.message == 'Alteração de senha bloqueada')
          this.isPasswordChangeBlocked = true;

        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async onForgotPassword() {
      try {
        this.onLoading(true);
        const { data } = await this.createPasswordReset(this.email);
        this.successNotify(data);
        this.$emit('close-popup');
        this.forceLogout();
      } catch (error) {
        if (error.response.status == 429) {
          this.errorNotify('reset_pass_rate_limit');
        } else {
          if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        }
      } finally {
        this.onLoading(false);
      }
    },

    async verifyIfUserCanReset() {
      try {
        this.onLoading(true);
        const { data } = await this.checkResetPass();
        this.canUserReset = data.can_reset;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  created() {
    this.verifyIfUserCanReset();
  }
};
</script>
